
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageBlogPostLayout} from 'layouts/CmsPageBlogPostLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageBlogPostLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Hi! I’m Emanuele and I’ve decided to write a blog.`}</p>
    <p>{`In my life I’m a web designer working as a freelance. From time to time I’ll write posts related to my job, so I can help you if you got here looking for useful information.`}</p>
    <p>{`I’m also a free spirit and I love adventures and the outdoors. This blog will be my journal where I’ll write stories about my bike rides, my hikes, my travels…`}</p>
    <p>{`This way I’ll share a little of what I love to do, so you can get to know me better. I hope you’ll also find some ideas in these pages.`}</p>
    <p><a parentName="p" {...{
        "href": "contact"
      }}>{`Don’t hesitate to contact me`}</a>{` if you’ll have suggestions helping me to improve or if you’ll find errors in this website. Your contribution is invaluable!`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    